import React from 'react'

import { getDate, getMonth } from "utils/date-formatter"

const BlogItem = ({blog}) => {
  return (
    <div className="blog-item">
      <a href={`/blog${blog.fields.slug}`} className="blog-img">
        <img
          src={blog.frontmatter.image.replace("/static/", "")}
          alt="blog-one"
        />
      </a>
      <div className="blog-info">
        <div className="date-box">
          {getDate(blog.frontmatter.date)}{" "}
          <span className="month">
            {getMonth(blog.frontmatter.date)}
          </span>
        </div>
        <div className="title-meta">
          <h2>
            <a href={`/blog${blog.fields.slug}`}>{blog.frontmatter.title}</a>
          </h2>
          <div className="post-meta">
            <i className="fa fa-tags"></i>
            {" "}Tags:{" "}
            <ul className="d-inline">
              {
                blog.frontmatter.tags.map((tag) => (
                  <li><a href={`/blog-list/${tag}`}>{tag}</a></li>
                ))
              }
            </ul>
          </div>
        </div>
      </div>
      <div className="post-content">
        <p>{blog.frontmatter.description}</p>
      </div>
    </div>
  )
}

export default BlogItem