import { useAsync } from "react-use";
import algoliasearch from "algoliasearch/lite";

/**
 * Hook for components to get Algolia facet values for tag
 * based on the current query.
 *
 * @returns {object}
 */
export function useFilterByTags(query) {
  const { loading, error, value } = _useAlgoliaFilter("tags", query);

  return {
    hits: value?.hits,
    isLoading: loading,
    error,
  };
}

/**
 * Hook that handles Algolia facets, fetching and returning proper data.
 *
 * @param {string} facetName
 * @returns {object}
 */
export function _useAlgoliaFilter(facetName, query) {
  const client = algoliasearch(
    process.env.GATSBY_ALGOLIA_APPLICATION_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY,
  );
  const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX);
  
  const asyncResult = useAsync(async () => {
    if (!query) {
      return null;
    }
    return index.search("", {
      filters: `${facetName}:${query}`
    });
  }, [query]);

  return asyncResult;  
}
