import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import { Router } from "@reach/router"

import Layout from '../../components/App/Layout'
import BlogItem from '../../components/BlogItem'
import { useFilterByTags } from '../../hooks/use_algolia_filter';

import { getDate, getMonth } from "utils/date-formatter"

const getRecentBlogs = graphql`
  {
    blogs: allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
            image
            tags
          }
        }
      }
    }
  }
`

const Blogs = () => {
    const res = useStaticQuery(getRecentBlogs)
    const blogs = res.blogs.edges

    const data = blogs.map(({ node: blog }) => (
        <div className="col-md-6 col-lg-6" key={blog.id}>
          <BlogItem blog={blog}/>
        </div>
    ))

    return (
        <div className="row">
            {data}
        </div>
    )
}

const BlogsByTag = ({tag}) => {
    // console.log(useTagFacetValues)
    const {hits, loading, error} = useFilterByTags(tag)
    // console.log(tag, error);
    if(loading || error) return null;

    return (
        <>
            <div className="section-title">
                <h2>{tag}</h2>
            </div>
            <div className="row">
                {hits?.map((blog) => (
                    <div className="col-md-6 col-lg-6" key={blog.objectID}>
                        <div className="blog-item">
                            <a href={`/blog/${blog.objectID}`} className="blog-img">
                                <img
                                src={blog.image.replace("/static/", "/")}
                                alt="blog-one"
                                />
                            </a>
                            <div className="blog-info">
                                <div className="date-box">
                                {getDate(blog.date)}{" "}
                                <span className="month">
                                    {getMonth(blog.date)}
                                </span>
                                </div>
                                <div className="title-meta">
                                <h2>
                                    <a href={`/blog/${blog.objectID}`}>{blog.title}</a>
                                </h2>
                                <div className="post-meta">
                                    <i className="fa fa-tags"></i>
                                    {" "}Tags:{" "}
                                    <ul className="d-inline">
                                        {
                                        blog.tags.map((tag) => (
                                            <li>{tag}</li>
                                        ))}
                                    </ul>
                                </div>
                                </div>
                            </div>
                            <div className="post-content">
                                <p>{blog.description}</p>
                            </div>
                            </div>
                    </div>
                ))}
            </div>
        </>
    )
}

const BlogList = () => {
    return (
        <Layout location="blog">
            <div className="bread-cumbs-area bread-cumbs-bg">
                <div className="diplay-table">
                    <div className="display-table-cell">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-7">
                                    <h1>Our Blog</h1>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ac augue at erat hendrerit dictum. Praesent porta, purus eget sagittis imperdiet.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  

            <section id="blog" className="our-blog main-blog bg-none">
                <div className="container">
                    <Router>
                        <BlogsByTag path="/blog-list/:tag" />
                        <Blogs path="/blog-list" />
                    </Router>
                        {/* <div className="col-lg-12 pagination-area text-center">
                            <ul className="pagination">
                                <li><Link to="#"><i className="fa fa-angle-left"></i></Link></li>
                                <li className="active"><Link to="#">1</Link></li>
                                <li><Link to="#">2</Link></li>
                                <li><Link to="#">3</Link></li>
                                <li><Link to="#"><i className="fa fa-angle-right"></i></Link></li>
                            </ul>
                        </div> */}
                </div>
            </section>
        </Layout>
    )
}

export default BlogList
